@import "normalize";

$vinun-radius: 8px;
$vinun-blue: #0e7dc2;
$vinun-green: #87c946;
$vinun-yellow: #fcfbdb;
$vinun-grey: #636363;
$vinun-lite: lighten($vinun-grey, 50%);
$vinun-lite2: lighten($vinun-grey, 46%);
$vinun-lite3: lighten($vinun-grey, 36%);

.text-blue {
	color: $vinun-blue;
}
.text-green {
	color: $vinun-green;
}
.text-yellow {
	color: $vinun-yellow;
}
.text-grey {
	color: $vinun-grey;
}

@import "settings";

// Include all foundation
// @import "foundation";

// Or selectively include components
// @import
//   "foundation/components/accordion",
//   "foundation/components/alert-boxes",

@import "foundation/components/block-grid";


//   "foundation/components/breadcrumbs",
//   "foundation/components/button-groups",
//   "foundation/components/buttons",
//   "foundation/components/clearing",
//   "foundation/components/dropdown",
//   "foundation/components/dropdown-buttons",
@import "foundation/components/flex-video";
@import "foundation/components/forms";
@import "foundation/components/grid";
//   "foundation/components/inline-lists",
//   "foundation/components/joyride",
//   "foundation/components/keystrokes",
//   "foundation/components/labels",
//   "foundation/components/magellan",
@import "foundation/components/orbit";
//   "foundation/components/pagination",
//   "foundation/components/panels",
//   "foundation/components/pricing-tables",
//   "foundation/components/progress-bars",
//   "foundation/components/reveal",
//   "foundation/components/side-nav",
//   "foundation/components/split-buttons",
//   "foundation/components/sub-nav",
//   "foundation/components/switches",
@import "foundation/components/tables";
//   "foundation/components/tabs",
//   "foundation/components/thumbs",
//   "foundation/components/tooltips",
//   "foundation/components/top-bar",
//   "foundation/components/type",
@import "foundation/components/offcanvas";
@import "foundation/components/visibility";

@import "animations";

$padded1: rem-calc(20);
$padded2: rem-calc(40);
$padded3: rem-calc(60);



@media #{$medium-up} {
	body {
		p {
			font-size: 1.05rem;
			line-height: 2;
		}
	}
}

@mixin linear-gradient($direction, $color-stops...) {
	background: nth(nth($color-stops, 1), 1);
	background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
	background: linear-gradient($direction, $color-stops);
}




html body {
	h1, h2, h3, h4, h5, h6 {
		font-family: 'Open Sans', sans-serif !important;
		font-weight: 700;
	}

	font-family: 'Open Sans', sans-serif !important;
}


@mixin link-color($color) {
	a {
		color: $color;
		transition: color 0.25s;

		&:hover {
			color: darken($color, 25%);
			transition: color 0.25s;
		}
	}
}

@mixin link-color-from-to($col1, $col2) {
	a {
		color: $col1;
		transition: color 0.25s;

		&:hover {
			color: $col2;
			transition: color 0.25s;
		}
	}
}








@mixin text-color($col1, $col2) {
	color: $col1;

	p {
		color: $col1;
	}

	h1, h2, h3, h4, h5, h6 {
		color: $col2;
	}
}











body {
	@include text-color($vinun-grey, $vinun-blue);

	@include link-color($vinun-blue);

	background: url('/images/bak2.gif');
}

.body-container {
	max-width: $row-width;
	margin: 0 auto;
	/*box-shadow: 0px 0px 4px darken($vinun-blue, 10%);*/
	box-shadow: 0px 0px 4px darken($vinun-grey, 15%);
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}



article {
	/*outline: 1px solid red;*/

	.page-column {
		padding: $column-gutter;
		background: #FFF;
		border-radius: $vinun-radius;
	}

	header {
		position: relative;
		z-index: 2;
		text-align: center;
	}

	h1 {
		&.article-header {
			@media #{$medium-up} {
				@include linear-gradient(#FFF, $vinun-lite);
				border-top: 5px solid $vinun-blue;
				box-shadow: 0px -16px 16px rgba(0,0,0,0.1);
				max-width: 800px;
				border-radius: $vinun-radius * 2;
				padding: (($column-gutter * 2) / 3) $column-gutter * 2;
				margin-bottom: -60px;
				hyphens: auto !important;
				position: relative;
				border-bottom: none;
				display: inline-block;
				top: -60px;
			}

			display: block;
			position: relative;
			border-bottom: 1px solid $vinun-grey;
			margin-bottom: 30px;
			padding-bottom: 30px;
			background: $vinun-lite;
		}
	}

	.article-figure-container {
		@media #{$medium-up} {
			max-width: 200px;

			&.lefter {
				float: left;
				margin-right: 25px !important;
				margin-bottom: 10px !important;
			}
			&.righter {
				float: right;
				margin-left: 25px !important;
				margin-bottom: 10px !important;
			}
		}

		@media #{$large-up} {
			max-width: 300px;

			&.lefter {
				float: left;
				margin: 0 25px 10px -15% !important;
			}
			&.righter {
				float: right;
				margin: 0 -15% 10px 25px !important;
			}
		}

		@media #{$xlarge-up} {
			max-width: 360px;
		}

		&.lefter,
		&.righter {
			display: block;
			margin: 0 0 25px 0;
		}

		width: 100%;

		text-align: center;

		img.article-image {
			height: auto;
			box-shadow: 1px 1px 3px rgba($vinun-grey, 0.25);
			margin: 0 auto;
			/*max-height: 500px;
			object-fit: contain;*/
			display: inline-block;
			width: 100%;
		}

		figure {
			margin: 0 !important;
			padding: 0 !important;
			border: 0 !important;
		}
	}
}


/* bars */
.vinun-border {
	border-bottom: 5px solid $vinun-blue;
}
.white-bar {
	&.toppur {
		height: 150px;

		@media #{$medium-up} {
			height: 180px;
		}
	}
	background: #FFF;
	@include link-color($vinun-grey);
	@include text-color($vinun-grey, $vinun-blue);
}
.lite-bar {
	background: $vinun-lite;
	@include link-color($vinun-grey);
	@include text-color($vinun-grey, $vinun-blue);
}
.lite2-bar {
	border-top: 1px solid $vinun-lite3;
	background: $vinun-lite2;
	@include link-color(#FFF);
	@include text-color(#FFF, #FFF);
}
.grey-bar {
	background: $vinun-grey;
	@include link-color(#FFF);
	@include text-color(#FFF, #FFF);
}
.blue-bar {
	background: $vinun-blue;
	@include link-color-from-to(#FFF, $vinun-green);
	@include text-color(#FFF, #FFF);
}
.green-bar {
	/*border-top: 5px solid $vinun-blue;*/
	/*background: $vinun-green;*/
	background: url('/images/bottom.png');
	@include link-color-from-to(#FFF, #FFF);
	@include text-color(#FFF, #FFF);
}






#logo {
	img {
		display: block;
		height: 70px;

		@media #{$medium-up} {
			height: 100px;
			height: auto;
			width: auto;
		}
	}
}





.notice {
	i {
		line-height: 100%;
	}

	font-size: 30px;

	@media #{$large-up} {
		font-size: 36px;
	}

	line-height: 100%;

	&.smaller {
		font-size: 24px;

		@media #{$large-up} {
			font-size: 30px;
		}
	}

	&.top {
		position: absolute;
		top: $padded1;
		right: $padded1;
	}
}

.takki,
button.takki,
a.takki {
	border: 2px solid $vinun-grey;
	background: none;
	padding: rem-calc(10) rem-calc(14);
	color: $vinun-grey !important;
	transition: all 0.25s !important;
	font-weight: 700;
	display: block;
	text-align: center;
	border-radius: $vinun-radius;
	
	@media #{$medium-up} {
		display: inline-block;
	}

	&.small {
		padding: rem-calc(4) rem-calc(8);
	}
}

.takki {
	&:hover {
		background: $vinun-blue;
		border: 2px solid $vinun-blue;
		transition: all 0.25s !important;
		color: #FFF !important;
		text-decoration: none;
	}
}

nav {
	&.topmobile {
		ul {
			margin:0;
			padding:0;
		}
		li {
			margin:0;
			padding:0;

			@include link-color-from-to(#FFF, #FFF);

			a {
				font-weight: 700;
				display: block;
				border-bottom: 1px solid rgba(255,255,255, 0.25);
				padding: rem-calc(15);
			}
		}
	}

	&.top {
		padding: 0;
		margin: 0;

		position: absolute;

		right: $padded1;
		bottom: $padded1;
		line-height: 100%;

		&.top-left {
			li {
				margin: 0;
			}

			left: $padded1;
			bottom: $padded1;
		}

		ul li ul {
			display: none;
		}

		li {
			line-height: 100%;
			padding: 0;
			margin: 0;
			float: left;

			&.active {
				a {
					color: $vinun-green;
					transition: color 0.25s;
				}
			}
		}

		@media #{$medium-up} {
			li {
				margin-left: 20px;
			}

			a {
				line-height: 100%;
				font-size: rem-calc(15);
				font-weight: 700;
				padding: 0;
				margin: 0;
				display: block;
			}
		}

		@media #{$xlarge-up} {
			li {
				margin-left: 40px;
			}

			a {
				line-height: 100%;
				font-size: rem-calc(20);
				font-weight: 700;
				padding: 0;
				margin: 0;
				display: block;
			}
		}
	}
}





#banner {
	position: relative;

	&.page {
		height: 200px;

		@media #{$medium-up} {
			height: 400px;
		}
	}

	#banner-shade {
		position: absolute;
		top: 0;
		z-index: 1;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		box-shadow: inset 0px -100px 200px rgba(0,0,0,0.65);
	}
}



.frontpage-orbit {
	position: relative;

	@include text-color(#FFF, #FFF);

	text-shadow: 0px 1px 0px #000;

	h1, h2, h3, h4, h5, h6 {
		padding: 0;
		margin: 0;
	}

	.frontpage-orbit-shade {
		position: absolute;
		top: 0;
		z-index: 9999;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		box-shadow: inset 0px -100px 250px rgba(0,0,0,1);
	}

	.frontpage-orbit-caption {
		visibility: hidden;
		z-index: 120000;
		position: absolute;
		bottom: $padded2;
		left: $padded2;
	}
}









.row.leftrightfix {
  padding-left: $column-gutter / 2;
  padding-right: $column-gutter / 2;
}


.column-gutter {
	padding: $column-gutter;
}
.column-gutter-right {
	padding-right: $column-gutter;
}
.column-gutter-left {
	padding-left: $column-gutter;
}
.column-gutter-top {
	padding-top: $column-gutter;
}
.column-gutter-bottom {
	padding-bottom: $column-gutter;
}

.position-relative {
	position: relative;
}
.display-block {
	display: block;
}


.margin-left {
	margin-left: rem-calc(10);
}
.medium-margin-left {
	margin-left: rem-calc(20);
}
.large-margin-left {
	margin-left: rem-calc(30);
}
.margin-right {
	margin-right: rem-calc(10);
}
.medium-margin-right {
	margin-right: rem-calc(20);
}
.large-margin-right {
	margin-right: rem-calc(30);
}

.padded1 {
	padding: $padded1;
}
.padded2 {
	padding: $padded2;
}
.padded3 {
	padding: $padded3;
}

.text-bold {
	font-weight: bold;
}
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.text-italic {
	font-style: italic;
}

.size-12 { font-size: 12px; }
.size-14 { font-size: 14px; }
.size-16 { font-size: 16px; }
.size-18 { font-size: 18px; }
.size-21 { font-size: 21px; }
.size-24 { font-size: 24px; }
.size-36 { font-size: 36px; }
.size-48 { font-size: 48px; }
.size-60 { font-size: 60px; }
.size-72 { font-size: 72px; }


@import "boxes";

.mobile-menu-open {
	span {
	}
	i {
		border: 2px solid $vinun-grey;
		border-radius: $vinun-radius;
		padding-left: 10px;
		padding-right: 10px;
		line-height: 100% !important;
		position: absolute;
		bottom: 0px;
		right: 0px;
	}
}

.left-off-canvas-menu {
	background: $vinun-blue;
}




table {
	&.frettir {
		border: none;
		width: 100%;

		td:first-child {
			width: 1%;
		}

		td {
			width: 99%;
			background: #FFF;
			vertical-align: top;
			border-bottom: 1px solid #DDD;
		}
	}
}




.newsbox {
	background: #FFF;
	border-radius: $vinun-radius;
	overflow: hidden !important;
	box-shadow: 1px 1px 3px rgba($vinun-grey, 0.25);

	&--padded {
		padding: rem-calc(10);
	}

	&--image {
		/*border-bottom: 1px solid $vinun-lite3;*/
		border-top-left-radius: $vinun-radius;
		border-top-right-radius: $vinun-radius;
		display: block;
		position: relative;
		height: 240px;
	}

	&--info {
		h3 {
			font-size: rem-calc(18);
			color: $vinun-grey;
		}

		.excerpt {
			font-size: rem-calc(15);
		}

		.date {
			font-size: rem-calc(13);
		}
	}
}