.slideLeft{
	animation-name: slideLeft;
	-webkit-animation-name: slideLeft;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes slideLeft {
	0% {
		transform: translateX(50%);
		opacity: 0;
	}		
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}

@-webkit-keyframes slideLeft {
	0% {
		-webkit-transform: translateX(50%);
		-webkit-opacity: 0;
	}
	100%{
		-webkit-transform: translateX(0%);
		-webkit-opacity: 1;
	}
}






.slideUp{
	animation-name: slideUp;
	-webkit-animation-name: slideUp;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;

	visibility: visible !important;			
}

@keyframes slideUp {
	0% {
		transform: translateY(50%);
		opacity: 0;
	}		
	100% {
		transform: translateY(0%);
		opacity: 1;
	}
}

@-webkit-keyframes slideUp {
	0% {
		-webkit-transform: translateY(50%);
		-webkit-opacity: 0;
	}
	100%{
		-webkit-transform: translateY(0%);
		-webkit-opacity: 1;
	}
}
