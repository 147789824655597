/* boxes */
.box-container {
	visibility: hidden;
	background: #FFF;
	margin: 0 auto;
	height: 300px;
	max-width: 300px;
	/*max-height: 300px;*/
	display: block;
	border-radius: 50%;

	&:hover {
		.Box {
			transform: scale(1.050,1.050);
			-webkit-transform: scale(1.050,1.050);
			-moz-transform: scale(1.050,1.050);
			-ms-transform: scale(1.050,1.050);
		}

		/*border: 3px solid $vinun-blue;

		transition: border 0.5s;*/
		.Box--text {
			color: #FFF;
			text-shadow: 0px 0px 4px darken($vinun-blue, 80%);
			transition: all 0.35s;
		}

		.Box--green-overlay {
			opacity: 0.85;
			transition: opacity 0.35s;
		}

		.Box--grey-overlay {
			opacity: 0;
			transition: opacity 0.35s;
		}
	}
}

.Box {
	/*border: 6px solid #FFF;*/
	position: relative;
	height: 100%;
	width: 100%;
	/*box-shadow: 3px 3px 6px rgba($vinun-grey, 0.5);*/
	background-position: center center;
	background-repeat: no-repeat;
	display: table;
	z-index: 0;
	border-radius: 50%;
	overflow: hidden;

	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;    
	-ms-background-size: 100% 100%;

    transition: all 0.35s ease-in;
    -webkit-transition: all 0.35s ease-in;
    -moz-transition: all 0.35s ease-in;
    -ms-transition: all 0.35s ease-in;

	background-color: transparent;
	

	&--text {
		display: table-cell;
		vertical-align: middle;
		position: relative;
		font-size: rem-calc(20);
		z-index: 3;
		text-align: center;
		border-radius: 50%;
		font-weight: 700;
		color: darken($vinun-grey, 25%);
		transition: all 0.35s;
		/*text-shadow: 0px 0px 24px #FFF;*/
		display: table-cell;
		vertical-align: middle;
	}

	&--green-overlay {
		@include linear-gradient($vinun-blue, darken($vinun-blue, 20%));
		transition: opacity 0.35s;
		opacity: 0;
		border-radius: 50%;
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&--grey-overlay {
		@include linear-gradient($vinun-grey, darken($vinun-grey, 20%));
		transition: opacity 0.35s;
		opacity: 0.85;
		z-index: 1;
		position: absolute;
		border-radius: 50%;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&--link {
		z-index: 5;
		position: absolute;
		top: 0;
		border-radius: 50%;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
}